.container {
  display: flex;
  flex-grow: 1;
  padding-top: 30px;
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.positionDetailsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #E5E5E5;
  width: 100%;
  padding: 16px 0px 16px 0px;
}

.positionDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-items: center;
}

.positionText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}

.shiftOriginText {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
  font-style: italic;
  margin-top: 0.5rem;
}