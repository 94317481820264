@value BACKGROUND_TABLE_STRIPED, BACKGROUND_TABLE_HEADER, ALERT_BACKGROUND, ALERT_TEXT from '../../constants/theme.css';


.tableHead {
  text-transform: uppercase;
  background: BACKGROUND_TABLE_HEADER;
}
.tableHeadCell {
  color: #fff;
  height: 40px;
  padding: 6px 0 6px 16px;
  text-align: center;
  font-size: 9pt;
}
.tableHeadCell:last-child {
  text-align: center;
}

.tableHeadCell svg {
  margin-left: 5px;
}

.tableHeadCellTime {
  composes: tableHeadCell;
  padding: 0 50px;
  text-align: center;
}

.tableCell {
  border-bottom: none;
  padding: 6px 0px 6px 16px;
  font-size: 10pt;
}

.tableCell:last-child {
  text-align: center;
}

.clickableRow {
  cursor: pointer;
}
.clickableRow:hover {
  opacity: 0.8;
}

.tableRow {
  composes: clickableRow;
}

.tableRow:nth-of-type(odd) {
  background-color: BACKGROUND_TABLE_STRIPED;
}

.tableRowToday {
  composes: clickableRow;
  background-color: ALERT_BACKGROUND;
}

.tableCellToday {
  composes: tableCell;
  color: ALERT_TEXT;
}

.badge {
  background-color: #FB6F70;
  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.applicantsBadgeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.colorRed {
  color: red;
}

.checkMark {
  vertical-align: top;
  margin-right: 0.25em;
  margin-left: -1.25em;
}