.chatList {
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.names {
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}


.emptyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.avatarSmall {
  height: 25px;
  width: 25px;
  font-size: 12px;
}

.avatarLarge {
  height: 40px;
  width: 40px;
}
