.contentContainer {
  padding: 40px;
  padding-top: 10px;
}

.flexWrapper {
  display: flex;
}

.flexWrapper input {
  min-width: 500px;
}

.gridRow {
  width: 100%;
}

.tooltip {
  margin: 0 5px;
}

.gridColumn {
  width: 50%;
}

.gridItem {
  margin: 20px 0;
}

.placesInput {
  width: 100%;
  margin: 20px 0;
}

.invoiceAddress {
  composes: gridRow;
  margin-top: 5px;
}

.submitBox {
  margin-top: 25px;
}

.formField {
  width: 90%;
}

.businessField {
  width: 100%;
  display: flex;
  align-items: center;
}

.saveButton {
  background-color: #65C87A;
}

.saveButton:hover {
  background-color: #40b258;
}

.closeButton {
  background-color: #A8A8A8;
  color: #fff;
}

.searchInput {
  margin-top: 1rem;
  width: 100%;
}
