.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.content {
  padding: 52px 52px 16px 52px;
  overflow-y: auto;
  text-align: center;
}

.description {
  max-width: 80%;
  margin: 12px auto 0 auto;
  font-size: 16px;
  line-height: 20px;
  color: #6B7280;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
}