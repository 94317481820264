@value GRAY_BLUE, BACKGROUND_GRAY from '../../../constants/theme.css';


.header {
  display: flex;
  min-height: 48px; /* safari fix */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 24px 4px 36px;
  position: sticky;
  left: 0;
}

.searchWrapper {
  flex: 5;
}

.searchInput {
  width: 100%;
}

.notificationsWrapper {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.badge, .chip {
  color: #fff;
  background-color: GRAY_BLUE;
}

.chip {
  margin: 0 .5em;
}

.callText {
  font-size: 0.8rem;
  opacity: 0.7;
  white-space: nowrap;
}

.avatar {
  width: 30px;
  height: 30px;
  margin: -4px;
  font-size: 18px;
}

.popoverWrapper {
  height: 400px;
  width: 350px;
  display: flex;
  flex-direction: column;
  padding: auto;
}

.highlightedButtonWrapper {
  background-color: BACKGROUND_GRAY;
}

.notificationBtnLabel {
  font-size: 10px;
  margin-top: 6px;
}
