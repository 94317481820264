@value BUTTON_GREEN, BTUTTON_BLUE, BUTTON_ORANGE, RED_TEXT, GRAY_TEXT from '../../constants/theme.css';

.wrapper {
  display: flex;
  align-items: center;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}

.badgeSuccess {
  color: #FFF;
}

.label {
  color: #000;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  margin-left: 20px;
}

.circleEmpty {
  background-color: #ccc;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  margin-left: 3px;
  margin-right: 4px;
}

.icon {
  font-size: 25pt;
}

.greenIcon {
  composes: icon;
  color: BUTTON_GREEN;
}

.blueIcon {
  composes: icon;
  color: BUTTON_BLUE;
}

.grayIcon {
  composes: icon;
  color: GRAY_TEXT;
}

.orangeIcon {
  composes: icon;
  color: BUTTON_ORANGE;
}

.redIcon {
  composes: icon;
  color: RED_TEXT;
}