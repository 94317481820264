@value SCHEDULE_SELECTED_TEXT, SCHEDULE_SELECTED, SCHEDULE_HIGHLIGHTED_TEXT, SCHEDULE_HIGHLIGHTED from '../../constants/theme.css';

.DayPicker-Day--hasShift {
  color: SCHEDULE_HIGHLIGHTED_TEXT;
  background-color: SCHEDULE_HIGHLIGHTED;
}

.DayPicker-Day--startDay {
  font-weight: bold;
  color: SCHEDULE_SELECTED_TEXT;
  background-color: SCHEDULE_SELECTED;
}
