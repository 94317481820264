.container {
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
  background-color: white;
  height: 100%;
}

.dragDropContainer {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.dragDrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #92E2A5;
  background-color: #F4FCF6;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  user-select: none;
  min-height: 125px;
}

.dropText {
  font-size: 16px;
}

.uploadText {
  color: #26C64B;
  text-decoration: underline;
}

.uploadIcon {
  margin-bottom: 16px;
  color: #26C64B;
}

.infoText {
  font-size: 12px;
  color: #737373;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 12px;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.modalContent {
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  max-width: 300px;
  text-align: center;
  row-gap: 100px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.modalInfo {
  font-size: 16px;
  line-height: 24px;
  color: #6B7280;
}

.modalButtons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.cancelButton {
  padding: 5px;
  display: flex;
  flex: 1;
}

.deleteButton {
  background-color: #EF4E4E;
  color: white;
  padding: 5px;
  display: flex;
  flex: 1;
}