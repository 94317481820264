.wrapper {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: #243545;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  flex-grow: 1 1 1;
  overflow: hidden;
}

.section {
  padding: 20px;
  flex: 6;
}

.logoWrapper {
  display: flex;
  flex: 1;
}

.title {
  text-align: center;
  flex: 6;
}

.search {
  display: flex;
  flex: 1;
  width: 600px;
  margin: 0 auto;
}

.searchInput {
  width: 100%;
}

.logo, .logout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
}

.logoutWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.logout {
  justify-content: flex-end;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  border: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: inherit;
  margin: 20px 0;
  text-align: left;
}

.logoutContainer {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 24px;
  justify-content: center;
  font-size: 14px;
}

.superadminText {
  font-size: 12px;
  color: rgba(255,255,255,0.4);
}

.buttonWrapper:focus {
  border: none;
  outline: none;
}

.optionLabelStaffer,
.optionLabelEmployee,
.optionLabelBusiness,
.optionLabelInvited {
  align-items: center;
  border-radius: 9px;
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 18px;
  justify-content: center;
  margin-left: 6px;
  padding: 0px 6px;
}

.optionLabelStaffer {
  background-color: green;
}

.optionLabelBusiness {
  background-color: orange;
}

.optionLabelEmployee,
.optionLabelInvited {
  background-color: blue;
}
