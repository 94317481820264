.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
}

.draggableFileUploadContainer {
  width: 100%;
  border: 2px dashed silver;
}