.modal {
  overflow-y: auto;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  gap: 24px;
  padding: 24px;
}

.noStaffers {
  padding: 24px;
  text-align: center;
}
