.paragraph {
  margin: 0 0 10px;
}

.blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.pre>.code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

.heading {
  color: unset;
  font-size: unset;
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h1.heading {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
h2.heading {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
h3.heading {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
h4.heading {
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
h5.heading {
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
h6.heading {
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}

.ul, .ol {
  padding-inline-start: 2em;
}
