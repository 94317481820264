.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container {
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #262626;
}

.content {
  display: flex;
  overflow: hidden;
  padding: 10px;
}

.noteFieldWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.noteField {
  width: 100%;
  margin-bottom: 20px;
}

.noteButtonsWrapper {
  display: flex;
  gap: 10px;
}
