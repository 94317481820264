.sidebar {
  width: 240px;
  color: white;
  transition: all 0.4s ease-in-out;
  border: none;
}

.collapsedSideBar {
  composes: sidebar;
  transition: all 0.4s ease-in-out;
  width: 80px;
}

.iconOpen {
  transform: rotate(180deg);
  transition: transform 0.4s ease;
}

.iconClose {
  transform: rotate(0deg);
  transition: transform 0.4s ease;
}

.header {
  display: flex;
  flex-direction: column;
  background-color: #262626;
}

.item {
  padding: 15px 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemActive {
  composes: item;
  background-color: #249c58;
}

.itemHighlighted {
  composes: item;
  border-left: solid 5px #2ECC71;
}

.childItem {
  padding: 10px;
  composes: item;
  color: #dcdde1;
  font-size: 0.75rem;
  font-weight: 400;
  background-color: #2d2d2d;
}

.childItemActive {
  composes: childItem;
  background-color: #249c58;
}

.item:hover {
  background-color: rgba(26, 26, 26, 1);
}
