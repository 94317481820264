.wrapper {
    width: 100%;
    position: relative;
}

.toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.textArea {
    border-radius: 10px;
    outline: 1px solid #999;
    padding: 18.5px 14px;
}
.textArea:hover {
    outline: 1px solid #333;
}
.focusedTextArea {
    composes: textArea;
    outline: 2px solid hsl(123, 50%, 62%);
}
.focusedTextArea:hover {
    outline: 2px solid hsl(123, 50%, 62%);
}

.toggleButton {
    margin: 0 1px;
}
