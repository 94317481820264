@value STRIPE_BLUE, BUTTON_RED, TITLE_BLACK from '../../constants/theme.css';


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  /*
      This weird translate values (50.1%) are set up to prevent blurry text in modals
      - see https://github.com/reactjs/react-modal/issues/188
   */
  transform: translate(-50.1%, -50.1%) !important;
  display: flex;
  max-width: calc(100% - 60px);
  min-width: 65vw !important;
  height: auto;
  max-height: calc(100% - 60px);
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 6px -3px, rgba(0, 0, 0, 0.14) 0px 10px 14px 1px, rgba(0, 0, 0, 0.12) 0px 4px 18px 3px;
  background: #fff;
  border-radius: 4px;
}

.enforcedWidthModal {
  composes: modal;
  min-width: 600px;
}

.enforcedSmallWidthModal {
  composes: modal;
  min-width: 420px;
}

.enforcedMediumWidthModal {
  composes: modal;
  min-width: 500px;
}

.enforcedLargeWidthModal {
  composes: modal;
  min-width: 600px;
}

.enforcedExtraLargeWidthModal {
  composes: modal;
  min-width: 860px;
}

.card {
  display: flex;
  flex-grow: 1;
}

.editCompanyProfileModal {
  composes: modal;
  height: 80%;
}

.createChatModal {
  composes: modal;
  min-width: 35vw;
}

.modalHeader {
  background-color: TITLE_BLACK;
  width: 100%;
  box-sizing: border-box;
}

.switchModalTypeWrapper {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
}

.modalContent {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
}

.modalTitle {
  color: #fff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 45px;
}

.headerIcon {
  color: #fff;
}

.modalSubtitle {
  font-weight: normal;
}

.darkChip {
  color: #fff;
  background-color: #7A7171;
}

.info {
  font-size: 1rem;
  font-style: italic;
  color: silver;
}

.tabContent {
  overflow-y: auto;
}

.inputWrapper {
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.input {
  margin: 8px 0;
  width: 300px;
}

.buttonWrapper {
  margin: 6px 0;
  width: 400px;
}

.infoHeader {
  composes: info;
  font-size: 1.5rem;
  text-align: center;
}

.closeButton {
  background: BUTTON_RED,
}

.internalShifts {
  border-left: 1px solid #E5E5E5;
}

.internalShiftsWidth {
  min-width: 90vw;
}

.beatLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 0px;
}

@media screen and (max-width: 1600px) {
  .modal {
    min-width: 85vw !important;
  }
}