.modalHeader {
  position: relative;
  padding: 16px 8px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #FAFAFA;
}

.modalHeaderLeftActions {
  position: absolute;
  left: 24px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.modalHeaderRightActions {
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}


.modalHeaderTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  min-height: 20px;
  text-align: center;
  margin: 0;
  flex-grow: 1;
}

.modalFooter {
  display: flex;
  padding: 10px 12px;
  border-top: 1px solid #e5e5e5;
  justify-content: space-between;
  background-color: #FAFAFA;
}
