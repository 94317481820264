@value TEXT_WHITE, BORDER_DARK_GRAY, TEXT_BLACK from '../../constants/index.css';

.messageContainer {
  margin-top: 15px;
  background-color: #FFF;
  border-radius: 15px;
  padding: 15px;
  width: 170px;
}

.messageText {
  font-size: 0.9em;
  color: #878787;
}

.messageImportant {
  font-size: 0.9em;
  font-weight: bold;
  color: #878787;
}

.messageButton {
  padding: 5px;
  border-radius: 15px;
  background-color: #979797;
  text-align: center;
}

.messageButton a {
  text-decoration: none;
  font-size: 0.9em;
  color: #FFF;
}

.container {
  background-color: TEXT_WHITE;
  width: 180px;
  border-radius: 5px;
  border: 0.5px solid BORDER_DARK_GRAY;
  flex: 1;
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  flex-direction: column;
  margin-bottom: 20px;
}

.innerButtonContainer{
  flex: 1;
  padding-bottom: 10px;
  align-items: center;
}

.buttonContainer {
  cursor: pointer;
  outline: none;
  color: BORDER_DARK_GRAY;
  font-size: 0.9em;
  align-items: center;
  border: none;
  background-color: TEXT_WHITE;
  text-decoration: none;
}

.buttonHighlighted{
  composes: buttonContainer;
  color: TEXT_BLACK;
  font-weight: bold;
  text-decoration: none;
}
