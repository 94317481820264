.wrapper {
  max-width: 1200px;
}

.filtersWrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 600px;
}

.filter {
  flex: 1;
  padding: 15px;
  min-width: 210px;
}

.targetFilter {
  margin: 20px 0 30px 0;
  min-width: 300px;
  max-width: 400px;
}

.inputWrapper {
  background-color: #fff;
  border-radius: 20px;
  min-width: 300px;
  max-width: 500px;
  padding: 10px 30px 20px;
  margin: 0px 0 20px 10px;
}

.input {
  width: 100%;
}

.h2 {
  margin: 20px 0 10px;
}

.paddingBottom {
  padding-bottom: 20px;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-size: 12px;
}

.borderRowWrapper {
  composes: rowWrapper;
  padding-bottom: 8px;
  margin-top: 20px;
  border-bottom: none;
}

.error {
  width: 100%;
  padding: 5px 0;
  color:red;
  border-top: 1px solid red;
  font-style: italic;
  max-height: 30px;
}

.promotionalOfferWrapper {
  font-size: 16px;
}
