.container {
  display: flex;
  flex: 1;
  background-color: #455361;
  border-radius: 8px;
  align-items: center;
  padding-right: 12px;
}

.searchWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 8px 0 12px;
}

.divider {
  width: 1px;
  display: flex;
  height: 28px;
  background-color: white;
  opacity: 0.25;
}
