.container {
  height: 50px;
  border-radius: 5px;
  border: 0.5px solid black;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  width: 190px;
  box-shadow: 2px 2px #888888;
  flex: 1;
  display: flex;
  padding-right: 10px;
}

.innerButtonContainer{
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
}

.titleContainer{
  flex: 1;
  display: flex;
  padding-left: 10px;
  cursor: pointer;
}

.jobTypeTitleContainer {
  padding-left: 20px;
}

.option{
  font-size: 16px;
  color: #989898;
}

.employmentContainer {
  composes: container;
  width: 300px;
}

.select{
  border: none;
  font-size: 16px;
  color: #989898;
  background-color: #FFFFFF;
  outline: none;
  padding-right: 1em;
  cursor: pointer;
}

