body {
  margin: 0;
  padding: 0 !important;
  overflow: auto;
  font-family: sans-serif;
}

body, html, #root {
  height: 100% !important;
}
/*
   So <Link /> components are not underlined
*/
a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}
