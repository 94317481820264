.container {
  background-color: white;
  border-radius: 24px;
  padding: 24px;
  size: 16px;
  box-shadow: 0px 1px 3px 0px #00000026;
  position: relative;
  display: flex;
  flex: 1;
}

.removeButton {
  position: absolute;
  right: 8%;
  top: 8%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.rowWrapper {
  flex-direction: row;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerContent {
  flex-direction: column;
  display: flex;
  padding-left: 12px;
}

.name {
  flex-direction: row;
  display: flex;
}

.badge {
  width: 16;
  height: 16;
  padding-left: 6px;
}

.rating {
  color: #26C64B;
}

.dot {
  padding: 5px;
  color: #A3A3A3;
}

.seeMore {
  color: #26C64B;
  text-decoration: underline;
  font-weight: 400;
  text-transform: unset;
  padding: 0;
}

.seeMore:hover {
  background-color: transparent;
  text-decoration: underline;
}

.contentWrapper {
  flex-direction: column;
  display: flex;
  padding-top: 24px;
  text-align: start;
  flex: 1;
}

.list {
  margin: 0;
  padding: 0 1em;
}

.greyText {
  font-weight: 400;
  color: #737373;
}

.boldText {
  font-weight: 600;
  color: #262626;
}

.blackText {
  font-weight: 400;
  color: #262626;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.buttonsWrapper {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.experiences {
  font-size: 14px;
  font-weight: 400;
  margin: 4px 0px;
}

.blockedIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: white;
  padding: 2px;
  left: 0px;
  bottom: 4px;
  border-radius: 100%;
}
