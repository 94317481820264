@value BACKGROUND_GRAY from '../constants/index.css';

.body {
  background-color: BACKGROUND_GRAY;
  min-height: calc(100% - 70px);
  display: flex;
}

.wrapper {
  height: 100%;
}

.menuWrapper {
  margin: 50px;
}

.layoutWrapper {
  flex: 1;
  margin: 50px 0;
}
