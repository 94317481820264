@value SCHEDULE_SELECTED_TEXT, SCHEDULE_SELECTED, SCHEDULE_HIGHLIGHTED_TEXT, SCHEDULE_HIGHLIGHTED from '../../constants/theme.css';

.rounded {
  border-radius: 50px;
  display: inline-block;
}

.hasShift {
  composes: rounded;
  border-radius: 50px;
  color: SCHEDULE_HIGHLIGHTED_TEXT;
  background-color: SCHEDULE_HIGHLIGHTED;
  
  box-shadow: 0 0 0 3px SCHEDULE_HIGHLIGHTED; /* spread background furter without involving layout */
}

.startDay {
  composes: rounded;
  font-weight: bold;
  color: SCHEDULE_SELECTED_TEXT;
  background-color: SCHEDULE_SELECTED;
  box-shadow: 0 0 0 3px SCHEDULE_SELECTED; /* spread background furter without involving layout */
}
