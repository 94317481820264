.modal {
  overflow-y: auto;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  padding: 52px;
}

.shortlistCardWrapper {
  position: relative;
}

.shortlistCard {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px 4px 16px 16px;
  
  text-align: left;
  color: #262626;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  background-color: white;
}

.shortlistCard:hover {
  border: 1px solid #D4D4D4;
}


.shortlistCardTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.shortlistCardInfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  color: #737373;
  font-size: 12px;
}

.shortlistCardIcon {
  padding: 4px;
  border-radius: 50%;
  color: #A3A3A3;
  position: relative;
  right: 15px;
  transition: color ease 0.2s;
}
*:hover > .shortlistCardIcon {
  color: #26C64B;
}

.shortlistEditIconButton {
  opacity: 0;
  border-radius: 50%;
  top: -8px;
  right: -8px;
  padding: 0;
  background: #eee;
  position: absolute;
  border: 1px solid #eee;
  transition: opacity 0.25s ease 0.25s;
}

.shortlistEditIcon {
  padding: 4px;
  color: #A3A3A3;
  font-size: 16px;
}

*:hover> .shortlistEditIconButton {
  opacity: 1;
}

.shortlistEditIconButton:hover {
  border-color: #aaa;
  cursor: pointer;
  background: #fff;
}

.shortlistCardInfoDot {
  display: inline-block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: #737373;
}