.wrapper {
  padding: 20px;
}

.contentWrapper {
  background-color: #FFF;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuButton {
  margin: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: space-evenly;
}

.checkboxContainer {
  margin: 10px 0;
}

.inputWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.codeInput {
  margin: 10px 0;
  width: 200px;
}

.phoneInput {
  border: 3px solid #bfdcfd !important;
  border-radius: 5px;
}

.phoneInput:focus {
  transition: all .4s ease-out;
}
