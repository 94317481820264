@value TEXT_WHITE, BUTTON_SUCCESS, BUTTON_SUCCESS_LIGHT, BORDER_DARK_GRAY from '../../constants/index.css';
@value BUTTON_BLUE from '../../constants/theme.css';


.loaderWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabPanel {
  display: flex;
  width: 100%;
}

.tabOption {
  width: 50%;
  border-bottom: 1px solid BORDER_DARK_GRAY !important;
}

.textButton {
  all: unset;
  color: BORDER_DARK_GRAY;
  text-decoration: underline;
}

.activeTextButton {
  all: unset;
  cursor: pointer;
  color: BUTTON_SUCCESS;
  text-decoration: underline;
}

/* css selector :focus works, but doesnt take uninitialized value into account */
.activeTabOption {
  width: 50%;
  background-color: TEXT_WHITE !important;
  border-radius: 15px 15px 0 0 !important;
  border-left: 1px solid BORDER_DARK_GRAY !important;
  border-top: 1px solid BORDER_DARK_GRAY !important;
  border-right: 1px solid BORDER_DARK_GRAY !important;
}

.formContainer {
  background-color: TEXT_WHITE;
  width: 400px;
  padding: 15px;
  border-right: 1px solid BORDER_DARK_GRAY;
  border-bottom: 1px solid BORDER_DARK_GRAY;
  border-left: 1px solid BORDER_DARK_GRAY;
  border-radius: 0 0 15px 15px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: TEXT_WHITE;
}

.formField {
  width: 100%;
  margin: 5px 0 !important;
}

.loginButton {
  margin-top: 15px;
  background-color: BUTTON_BLUE;
  color: TEXT_WHITE;
}

.loginButton:hover {
  background-color: rgba(19, 121, 188, 0.51);
  font-weight: bold;
}

.submitButton {
  margin-top: 15px;
  background-color: BUTTON_SUCCESS;
  color: TEXT_WHITE;
}

.submitButton:hover {
  background-color: #2ecc71;
  font-weight: bold;
}

.navigationButton {
  background-color: black;
  margin: 50px 0;
  color: TEXT_WHITE;
}

.navigationButton:hover {
  background-color: #2c3e50;
  font-weight: bold;
}

.buttonContent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.buttonContent > * {
  padding: 0 5px;
}

.textButton {
  text-decoration: underline;
  text-transform: none;
}

.textButton:hover {
  text-decoration: underline;
}


.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.centered {
  display: flex;
  justify-content: center;
}

.alertText {
  font-size: 12px;
  font-style: italic;
  color: #E6402D;
  margin-block-start: 0.5;
}

.signup {
  composes: centered;
  margin-top: 15px;
}

.signup button {
  text-decoration: none;
  font-size: 16px;
}

.signup button:hover {
  transition: ease-in 0.3s;
}

.signup button:focus {
  outline: 0;
}

.disabledSignup {
  display: none;
}

.indicator {
  display: none;
}
