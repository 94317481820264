.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.paginationContainer {
  display: flex;
  width: 95%;
  top: 50%;
  position: absolute;
  flex: 1;
  z-index: 25;
}

.paginationArrow{
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 10px;
  border-radius: 100%;
}

.arrowWrapperLeft {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.arrowWrapperRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.container {
  background-color: white;
  border-radius: 16px;
  width: 85%;
  height: 85%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: #262626;
  z-index: 30;
}

.smallContainer {
  background-color: white;
  border-radius: 16px;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: #262626;
  min-width: 504px;
}

.header {
  height: 56px;
  background-color: #FAFAFA;
  border-bottom: 1px solid #E5E5E5;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
}

.closeButton {
  position: absolute;
  right: 16px;
}

.testingUser {
  display: flex;
  background-color: #26C64B;
  color: white;
  font-size: 14px;
  justify-content: center;
}

.content {
  display: flex;
  overflow-y: scroll;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 25%;
  overflow-y: visible;
}

.blockedStatus {
  display: flex;
  padding: 10px 6px 10px 6px;
  background-color: #FDE5E5;
  justify-content: space-between;
  align-items: center;
}

.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  flex-direction: column;
}

.avatar {
  width: 112px;
  height: 112px;
  border-radius: 100%;
  margin-bottom: 24px;
  object-fit: cover;
  object-position: center;
  display: inline-block;
}

.verifiedUser {
  position: absolute;
  background-color: white;
  border-radius: 100%;
  width: 17px;
  height: 17px;
  padding: 2px;
  right: 6px;
  bottom: 36px;
}

.ratingWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.rowWrapper {
  display: flex;
  padding: 14px 24px 14px 24px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E5E5E5;
}

.ratingIcon {
  width: 16px;
  height: 16px;
  padding-right: 6px;
}

.greenText {
  color: #26C64B;
  font-size: 16px;
}

.greenTextSmall {
  color: #26C64B;
  font-size: 14px;
}

.greyText {
  color: #737373;
  font-weight: 400;
  text-align: start;
}

.sidebarInfo {
  color: #737373;
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}

.textSmall {
  font-size: 14px;
}

.textBold {
  font-weight: 600;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E5E5E5;
  padding: 20px;
}

.button {
  border-radius: 8px;
  color: #262626;
  text-transform: unset;
  margin-top: 10px;
}

.buttonRed {
  border-radius: 8px;
  color: white;
  background-color: #EF4E4E;
  border-color: #EF4E4E;
  text-transform: unset;
  margin-top: 10px;
}

.buttonRed:hover {
  background-color: #e73838;
}

.contentWrapper {
  padding: 0px 24px 0px 24px;
  flex-direction: column;
  display: flex;
  flex: 1;
  border-left: 1px solid #E5E5E5;
  height: max-content;
  overflow: auto;
}

.contentColumnWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 0px 24px 0px;
  border-bottom: 1px solid #E5E5E5;
}

.rowContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.documentCard {
  display: flex;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid #E5E5E5;
}

.documentRowContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.documentColumnContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
}

.documentImg {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.link {
  color: #26C64B;
  font-size: 14px;
  text-decoration: underline;
  padding-top: 4px;
}

.awaitingText {
  font-size: 16px;
  color: #ff931e;
}

.noDocuments {
  background-color: #F5F5F5;
  border-radius: 16px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.contentRow {
  display: flex;
  align-items: center;
  flex: 1;
}

.contentColumn {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  flex: 1;
}

.emptySection {
  margin-top: 16px;
  font-style: italic;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.titleBold {
  font-size: 24px;
  font-weight: 600;
}

.infoText {
  font-size: 16px;
  padding-top: 6px;
}

.smallIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.xsIcon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.chip {
  background-color: #F5F5F5;
  padding: 10px 12px 10px 12px;
  border-radius: 6px;
  margin-right: 10px;
  align-items: center;
  display: flex;
  margin-top: 16px;
}

.jobChip {
  background-color: #F5F5F5;
  padding: 10px 12px;
  border-radius: 18px;
  margin-right: 10px;
  align-items: center;
  display: flex;
  margin-top: 16px;
}

.chipSmall {
  background-color: #F5F5F5;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  margin-left: 10px;
  align-items: center;
  display: flex;
  font-size: 12px;
}

.jobsWrapper {
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.jobCard {
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  box-shadow: 0px 1px 3px 0px #00000014;
}

.jobCardContent {
  display: flex;
  position: relative;
  align-items: start;
  justify-content: space-between;
}

.jobCardContentInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 16px;
}

.jobDateBadge {
  background-color: #F5F5F5;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-right: 20px;
  color: #262626;
}

.jobCardLinks {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 16px;
}

.jobCardLinksItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.jobCardFooter {
  padding: 10px 16px 10px 16px;
  border-top: 1px solid #E5E5E5;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.cardColumn {
  display: flex;
  flex-direction: column;
}

.wageChip {
  background-color: #F4FCF6;
  border-radius: 16px;
  padding: 6px 12px 6px 12px;
  color: #26C64B;
  display: flex;
  align-items: center;
}

.largeIcon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 50%;
}

.referralWrapper {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
}

.referralAvatarWrapper {
  position: relative;
  margin-right: 16px;
}

.referralAvatar {
  border-radius: 100%;
  width: 48px; 
  height: 48px;
  object-fit: cover;
  object-position: center;
  display: inline-block;
}

.referralVerifiedUser {
  position: absolute;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 100%;
  background-color: white;
  right: 0px;
  bottom: 2px;
}

.footer {
  height: 52px;
  border-top: 1px solid #E5E5E5;
  padding: 12px 24px 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyToClipboard {
  color: #26C64B;
  background-color: transparent;
  text-transform: unset;
}

.buttonFooter {
  text-transform: unset;
  border-radius: 8px;
  margin-left: 16px;
}

.dot {
  color: #A3A3A3;
  margin: 0px 5px 0px 5px;
}

.flagIcon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  margin-right: 6px;
}

.approvalModalContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 24px 30px;
}

.approvalRowTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.approvalModalRow {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.denyInfo {
  color: #6b7280;
  font-size: 16px;
  text-align: center;
}

.denyButton {
  background-color: #EF4E4E;
  color: white;
  margin-bottom: 8px;
}

.denyButton:hover {
  background-color: #EF4E4E;
}
.blockedIcon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.textBlocked {
  color: #EF4E4E;
  font-size: 14px;
  font-weight: 400;
}

