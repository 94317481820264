@value TEXT_WHITE, TEXT_GRAY, BUTTON_SUCCESS, BUTTON_DANGER, BUTTON_BLUE_PRIMARY, BORDER_DARK_GRAY  from '../../constants/index.css';
@value BUTTON_GREEN, BUTTON_GREEN_ACTIVE, BUTTON_GRAY, BUTTON_ORANGE, BUTTON_GRAY_ACTIVE, TITLE_BLACK from '../../constants/theme.css';

.EditShift {
  padding: 5px;
  min-height: 500px;
}

.flex {
  display: flex;
  width: 75%;
}

.italic {
  font-style: italic;
}

.note {
  font-size: small;
  color: TEXT_GRAY;
}

.noteWarn {
  font-size: small;
  color: BUTTON_ORANGE;
}

.flexGap {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.editShiftTooltip {
  margin-left: 10px;
}

.ml25 {
  margin-left: 25px;
}

.shiftDuration {
  margin: 30px 0;
}

.flexCard {
  display: flex;
  -webkit-tap-highlight-color: transparent;
  width: 50%;
  margin: 10px 0
}

.flexCard > div {
  width: 95%;
}

.right {
  margin-left: 5%;
}

.Modal { /* deprecated - do not use */
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  border: 1px solid #999;
  transform: translate(-50%, -50%) !important;
  height: auto;
  max-width: 1000px;
  min-width: 700px;
  outline: none;
  padding: 20px 70px 20px 70px;
  background-color: #fff;
}

.MuiModal {
  composes: modal from '../mui/Modal.module.css';
}

.largeMuiModal {
  composes: MuiModal;
  max-width: calc(100% - 10px);
  min-width: calc(90% - 10px);
}

.greenButton {
  margin-left: 1.0em;
  background-color: BUTTON_GREEN;
  color: #fff;
}

.confirmationGreenButton {
  composes: greenButton;
  width: 200px;
}

.greenButton:hover {
background-color: BUTTON_GREEN_ACTIVE;
}

.editCompanyModal {
composes: MuiModal;
height: calc(100% - 8rem);
overflow-y: auto;
}

.editJobModal {
composes: MuiModal;
max-height: 90%;
min-width: 750px;
}

.removeJobModal {
composes: MuiModal;
max-width: 400px;
min-width: 250px;
}

.inviteStaffersModal {
composes: MuiModal;
min-height: 300px;
min-width: 700px;
}

.confirmBirthdayModal {
composes: MuiModal;
min-height: 300px;
min-width: 250px;
}

h1 {
font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
"Lucida Sans", Arial, sans-serif;
font-size: 23px;
}

h2 {
font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
"Lucida Sans", Arial, sans-serif;
font-size: 16px;
color: #1778b8;
}

.subtitle {
margin-bottom: 0.5em;
margin-top: 2.5em;
color: TITLE_BLACK;
}
.subtitle:first-child {
  margin-top: 0.5em;
}

.columns {
display: flex;
margin-bottom: 0.5em;
}

.columnLeft {
flex: 1;
margin-right: 40px;
}

.select {
  width: 75%;
  margin-right: 16px;
}

.selectAccess,
.selectEmploymentReason,
.selectDepartments,
.selectBusiness,
.fullWidth {
width: 100%;
}

.columnHeader {
font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
"Lucida Sans", Arial, sans-serif;
margin-bottom: 25px;
text-align: center;
color: #989898;
}

.columnRight {
flex: 1;
margin-left: 20px;
}

.columnRightPaymentDetails {
flex: 1.5;
margin-left: 20px;
}

.description {
margin-top: 25px;
}

.descriptionProfile {
margin-top: 45px;
}

.descriptionTextArea {
width: 100%;
}

.rejectionMessgeTextArea {
box-sizing: border-box;
width: 100%;
min-height: 18rem;
padding: 0.5rem;
resize: vertical;
font-size: 1rem;
}

.jobOptionHeader {
margin-bottom: 10px;
}

.datePicker {
cursor: pointer;
width: 75%;
font-size: 15px;
opacity: 0.5;
}

.datePicker:hover {
transition: ease-in 0.2s;
font-weight: bold;
opacity: 0.9;
}

.buttonWrapper {
margin-top: 40px;
display: flex;
justify-content: center;
}

.buttonWrapperColumn {
composes: buttonWrapper;
margin-top: 25px;
align-items: center;
width: 100%;
}

.buttonWrapperColumn button {
margin: 0 8px 5px;
}

.rowButtonWrapper {
composes: buttonWrapper;
justify-content: space-evenly;
}

.columnWrapper {
margin-top: 10px;
display: flex;
justify-content: center;
margin-left: -20px;
margin-right: -20px;
}

.column {
padding: 20px;
display: flex;
flex-direction: column;
align-content: space-between;
flex: 1;
}
.wrapper {
display: flex;
justify-content: center;
flex: 1;
padding-top: 20px;
}

.resolveShift {
  composes: wrapper;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  height: 100%;
}

.buttonSave {
background-color: BUTTON_SUCCESS;
color: TEXT_WHITE;
font-size: 15px;
border: none;
border-radius: 20px;
padding: 10px 20px;
margin: 0 5px;
cursor: pointer;
align-self: flex-end;
}

.buttonSave[name=postJob] {
min-width: 180px;
}

.buttonSave200W {
composes: buttonSave;
padding: 8px;
width: 200px;
margin-bottom: 5px;
}

.buttonDisabled {
composes: buttonSave;
background-color: BORDER_DARK_GRAY;
}

.buttonDefault {
composes: buttonSave;
background-color: BORDER_DARK_GRAY;
}

.buttonClose {
composes: buttonSave;
background-color: BUTTON_BLUE_PRIMARY;
}

.shiftBadgeButton {
composes: buttonDefault;
width: 50%;
margin-bottom: 0.75em;
}

.cancelButton {
composes: buttonSave;
background-color: TEXT_WHITE;
border: 1px solid BORDER_DARK_GRAY;
border-radius: 20px;
color: #000000;
margin: 0 10px 10px 0;
}

.stafferNoShowCancelButton {
composes: cancelButton;
margin: 0;
}

.removeButton {
composes: buttonSave;
background-color: BUTTON_DANGER;
border: 1px solid BORDER_DARK_GRAY;
border-radius: 20px;
color: #fff;
}

.sendButton {
composes: buttonSave;
border-radius: 20px;
margin: 0 10px 10px 0;
}

.removeHeader {
text-align: center;
}

.buttonClose:hover {
box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.buttonSave:hover {
box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.saveButton:focus,
.buttonClose:focus {
outline: none;
}

h2.website {
margin-bottom: 8px;
}

.paymentDetailsWrapper {
padding: 0 20px 20px;
}

h2.paymentDetails {
font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
"Lucida Sans", Arial, sans-serif;
font-size: 16px;
color: #000000;
}

h1.paymentDetails {
font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
"Lucida Sans", Arial, sans-serif;
font-size: 23px;
color: #000000;
text-align: center;
}

input.websiteInput {
margin-bottom: 4px;
}

.textCenter {
text-align: center;
}

.loaderWrapper {
display: flex;
justify-content: center;
align-items: center;
height: 300px;
}

.textWarning {
color: orange;
}

.search {
flex: 5;
margin-bottom: 50px;
}

.searchInput {
width: 100%;
}

.showContactInfoAvatar {
border: none;
background-color: #FFFFFF;
margin-top: 10px;
margin-bottom: 10px;
outline: inherit;
display: flex;
align-items: center;
justify-content: center;
}

.showContactInfoToggle {
display: flex;
flex-direction: row;
text-align: center;
align-items: center;
justify-content: center;

margin-top: 20px
}

.showContactInfoToggle button {
margin: 0 0 14px 10px;
}

.showContactInfoToggleText {
margin: 0;
font-style: italic;
}

.addStafferWrapper {
display: flex;
align-items: center;
flex-direction: column;
padding: 0 0 20px 0;
}

.modalHeaderSubtext {
font-weight: normal;
}

.languageSelectorWrapper {
padding-top: 20px;
}

.agreementWrapper {
overflow: scroll;
height: 50vh;
width: 90%;
}

/* Adds a permanent scrollbar */
.agreementWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.agreementWrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.agreementHeader {
  margin: 16px;
}

.agreementContent {
  text-align: justify;
  font-size: 18px;
}

.languageButton {
  border: none;
  background: none;
  font-size: 18px;
  margin: 2px 6px;
  transition: .3s ease-in-out color;
  cursor: pointer;
}

.languageButton:hover {
  color: #2880B9;
}

.languageButton:focus {
  outline: none;
}

.languageButtonActive {
  composes: languageButton;
  color: #2880B9;
  border-bottom: 1px solid #2880B9;
}

.pageLoaderWrapper {
  height: 100%;
}

.mainGrid > * {
  width: 100%;
}

.commentInput {
  font-size: 16px;
  height: 100px;
  width: 100%;
}

.editCompanyAddressWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editCompanyAddressWrapper p {
  margin-left: -10px;
}

.tooltip {
  width: 20vw;
}

.editShiftTimeButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.info {
  font-style: italic;
  font-size: 12px;
  color: #989898;
}

.shareButton {
  composes: buttonSave;
  background-color: #248CD8;
  margin: 0 10px 10px 0;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.removeHeader {
  text-align: center;
}

.copyButton {
  background: #dedede;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .2s ease-in-out;
}

.shareRow {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.shareRow > * {
  margin: 0 5px;
}

.grayButton {
  margin: 1.0em;
  background-color: BUTTON_GRAY;
  color: #fff;
  width: 200px;
}

.grayButton:hover {
  background-color: BUTTON_GRAY_ACTIVE;
}

.defaultFab {
  background-color: #fff;
}

.svgIcon {
  color: white;
}

.confirmationModalWrapper {
  text-align: center;
  padding: 20px;
}

.inviteChip {
  background: darksalmon;
  color: white;
}

.partiallyFilledChip {
  background-color: #F2973F;
  color: white;
}

.inputWrapper {
  margin: 10px 0;
}

.listItem {
  font-size: 12px;
}

.smallText {
  font-size: 12px;
}

.smallTextBold {
  composes: smallText;
  font-weight: bold;
}

.panel {
  background: #f7f7f7;
}

.separator {
  padding-right: 5px;
}

.tooltipIcon {
  margin: 0 8px;
}

.paddingTop {
  padding-top: 2.5em;
}

.centerExternalTooltip {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButtonlabel {
  cursor: pointer;
  text-align: center;
}
.iconButtonlabel[disabled] {
  pointer-events: none;
}

.actionButtonTitle {
  font-size: 10px !important;
  margin: 0 0 6px;
  text-align: center;
}

.inputWrapper input[type="time"]::-webkit-calendar-picker-indicator {
  padding-left: 55%;
  margin: 0;
}

.inputWrapper input[type="time"]::-webkit-calendar-picker-indicator:focus {
  outline: 0;
}

.contractInput::placeholder {
  font-size: 12px;
}

.errorText {
  color: red;
  position: absolute;
  font-style: italic;
  font-size: 10px;
}

.cancellationAgreedTitle {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
}

.or {
  display: inline-flex;
  vertical-align: sub;
  padding: 0.5em;
}