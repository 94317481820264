.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container {
  background-color: white;
  border-radius: 16px;
  width: 85%;
  height: 85%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #262626;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* SIDEBAR */

.sidebar {
  display: flex;
  flex-direction: column;
  width: 25%;
  overflow: none;
}

.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  flex-direction: column;
  row-gap: 5px;
  user-select: none;
}

.avatar {
  width: 112px;
  height: 112px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  display: inline-block;
  margin-bottom: 5px;
}

.verifiedUser {
  position: absolute;
  background-color: white;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 2px;
  right: 6px;
  bottom: 12px;
}

.ratingWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.name {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  line-height: 20px;
}

.ratingText {
  color: #26C64B;
  font-size: 0.8rem;
  margin-left: 4px;
}

.shiftDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 14px;
  gap: 4px;
}

.verifiedIcon {
  height: 16px;
  width: 16px;
}

.verifiedWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  font-size: 0.75rem;
  gap: 4px
}

.ratingIcon {
  width: 16px;
  height: 16px;
}

.jobButtonsWrapper {
  display: flex;
  gap: 10px;
}

.jobButton {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
}

.jobButtonContent {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  justify-content: center;
}

.acceptCandidateIcon {
  width: 18px;
  height: 18px;
  color: #26C64B;
}

.declineCandidateIcon {
  width: 18px;
  height: 18px;
  color: #EF4E4E;
}

.personalInfoWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  background-color: #F5F5F5;
  row-gap: 12px;
}

.interactiveBtn {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
}

.btnChevron {
  width: 24px;
  height: 24px;
}

.btnChevronDown {
  width: 24px;
  height: 24px;
  rotate: 90deg;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E5E5E5;
  padding: 20px;
}

.linearBarSubtitle {
  font-size: 0.8rem;
  color: #737373;
}

/* GENERAL */

.linkBtn {
  padding: 0px 4px;
  color: #26C64B;
  font-size: 0.8rem;
  font-weight: 600;
}

.largeText {
  font-size: 1rem;
}

.greenText {
  color: #26C64B;
  font-size: 0.8rem;
}

.greenTextSmall {
  color: #26C64B;
  font-size: 0.8rem;
}

.greenTextBold {
  color: #26C64B;
  font-size: 1rem;
  font-weight: 600;
}

.greyText {
  color: #737373;
  font-weight: 400;
  text-align: start;
  font-size: 0.8rem;
}

.greyTextLarge {
  color: #737373;
  font-size: 1rem;
}

.textBold {
  font-weight: 600;
}

.contentRow {
  display: flex;
  align-items: center;
  flex: 1;
}

.contentColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.emptySection {
  font-style: italic;
  font-size: 1rem;
}

.title {
  font-size: 1rem;
  font-weight: 600;
}

.titleBold {
  font-size: 1.4rem;
  font-weight: 600;
}

.infoText {
  font-size: 0.8rem;
  padding-top: 3px;
}

.smallIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.xsIcon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

/* CONTENT */

.contentWrapper {
  padding: 0px 24px 0px 24px;
  flex-direction: column;
  display: flex;
  flex: 1;
  border-left: 1px solid #E5E5E5;
  overflow-y: auto;
}

.contentColumnWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 0px 24px 0px;
  border-bottom: 1px solid #E5E5E5;
  row-gap: 16px;
  line-height: 20px;
}

.chip {
  background-color: #F5F5F5;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  margin-right: 10px;
  align-items: center;
  display: flex;
  font-size: 0.8rem;
}

.chipSmall {
  background-color: #F5F5F5;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  margin-left: 10px;
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  color: #737373;
}

.cvContainer {
  display: flex;
  flex: 1;
  border-radius: 6px;
  background-color: #F5F5F5;
  gap: 6px;
  align-items: center;
  padding: 12px 18px;
  line-height: 16px;
  font-size: 0.8rem;
}

.divider {
  background-color: #D4D4D4;
  height: 16px;
  width: 1px;
}

.linkBtnSmall {
  padding: 0px 4px;
  color: #26C64B;
  font-size: 0.8rem;
  font-weight: 600;
}

/* EXPERIENCE CARD - CONTACT MODAL */

.rowContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.experienceRow {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.8rem;
}

.greyIcon {
  width: 18px;
  height: 18px;
  color: #737373;
}

.contactBtn {
  color: #26C64B;
  width: 40px;
  height: 40px;
  padding: 8px;
  border: 1px solid #D4D4D4;
  border-radius: 100%;
  margin-left: 12px;
}

.contactModal {
  position: absolute;
  top: 15%;
  background-color: white;
  border-radius: 16px;
  min-width: 600px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #262626;
}

.contactContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 24px;
}

.contactContentRow {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.contactContentRow > span {
  font-weight: 600;
}

/* FOOTER */

.footer {
  height: 52px;
  border-top: 1px solid #E5E5E5;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonFooter {
  text-transform: unset;
  border-radius: 8px;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 16px;
  max-height: 42px;
}

/* MODAL HEADER */

.modalHeader {
  position: relative;
  padding: 16px 8px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #FAFAFA;
  user-select: none;
}

.modalHeaderLeftActions {
  position: absolute;
  left: 24px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.modalHeaderRightActions {
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}


.modalHeaderTitle {
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  min-height: 20px;
  text-align: center;
  margin: 0;
  flex-grow: 1;
}

/* STATUS DOT */

.applied {
  padding: 6px;
  border-radius: 100%;
  background-color: #2C3E50;
}

.notFit {
  padding: 6px;
  border-radius: 100%;
  background-color: #EF4E4E;
}
.maybeAFit {
  padding: 6px;
  border-radius: 100%;
  background-color: #FF931E;
}

.goodFit {
  padding: 6px;
  border-radius: 100%;
  background-color: #00AF9A;
}

.interviewed {
  padding: 6px;
  border-radius: 100%;
  background-color: #0071B0;
}

.hired {
  padding: 6px;
  border-radius: 100%;
  background-color: #26C64B;
}

/* SHIFTS MODAL */

.shiftsModal {
  position: absolute;
  top: 10%;
  background-color: white;
  border-radius: 16px;
  width: 45rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #262626;
  max-height: 80%;
  overflow: hidden;
}

.shiftsContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.shiftsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: hidden;
}

.shiftsRow {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 10px;
  align-items: center;
}

.shiftsRowHeader {
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  padding: 24px
}

.shiftsColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
}

.shiftsColumnHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  padding: 0px 24px;
}

.shiftsContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2px;
}

.shiftsRatingContainer {
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex: 1;
  background-color: #F5F5F5;
  gap: 20px;
  min-height: 5rem;
  align-items: center;
  justify-content: start;
  margin-right: 10px;
}

.shiftsRatingIcon {
  width: 24px;
  height: 24px;
  background-color: white;
  padding: 12px;
  border-radius: 100%;
}

.reviewsText {
  font-size: 0.8rem;
  color: #616E7C;
}

.progressBar {
  width: 500px;
  height: 8px;
  border-radius: 2px;
}

.shiftsChip {
  display: flex;
  padding: 6px 8px;
  background-color: #F5F5F5;
  border-radius: 4px;
  gap: 5px;
}

.shiftsTitle {
  font-size: 0.8rem;
  font-weight: 600;
}

.shiftsReviewContent {
  border-top: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
}

.shiftsReviewColumn {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.shiftsBusinessCover {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.shiftfsReviewRow {
  display: flex;
  flex: 1;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}


/* BREAKPOINTS SIDEBAR */

@media only screen and (max-height: 1000px) {

.avatarWrapper {
  padding-top: 12px;
  row-gap: 5px;
}

.avatar {
  width: 70px;
  height: 70px;
}

.verifiedUser {
  width: 16px;
  height: 16px;
  padding: 2px;
  right: 3px;
  bottom: 6px;
}

.jobButton {
  padding: 6px;
}

.jobButtonContent {
  font-size: 0.8rem;
}

.acceptCandidateIcon {
  width: 12px;
  height: 12px;
  color: #26C64B;
}

.declineCandidateIcon {
  width: 12px;
  height: 12px;
  color: #EF4E4E;
}

.personalInfoWrapper {
  padding: 10px;
  row-gap: 6px;
}

.interactiveBtn {
  padding: 6px;
  max-height: 35px;
}

.btnChevron {
  width: 20px;
  height: 20px;
}

.btnChevronDown {
  width: 20px;
  height: 20px;
  rotate: 90deg;
}

.columnWrapper {
  padding: 16px;
}

.addNoteBtn {
  max-height: 35px;
}

}