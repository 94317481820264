.wrapper {
  position: relative;
  display: flex;
  flex-grow: 999;
  height: 95vh;
}

.backButton {
  z-index: 2;
  position: absolute;
  top: .5em;
  left: .5em;
}

.frame {
  z-index: 1;
  flex-grow: 1;
  border: none;
}

.frameLoaded {
  composes: frame;
  background-color: white;
}

.loaderWrapper {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
}
