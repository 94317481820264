.imageListItem {
  border-radius: 8px;
  border: 1px solid #D4D4D4;
  margin-top: 12px;
  background-color: white;
  user-select: none;
}

.listItemContainer {
  padding: 12px 24px 12px 16px;
  display: flex;
  align-items: center;
}

.imageContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.image {
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  user-select: none;
}

.imageNameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 12px;
  min-width: 220px;
}

.imageName {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}

.progressBar {
  width: 100%;
  border-radius: 2px;
  margin-top: 8px;
}

.actionsContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.actionsText {
  font-size: 12px;
  font-weight: 400;
  margin-right: 12px;
  color: #737373;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 12px;
  color: #737373;
  padding: 10px;
}

.icon:hover {
  background-color: #F5F5F5;
  border-radius: 100%;
}

.dragIcon {
  width: 24px;
  height: 24px;
  cursor: grab;
  color: #737373;
  rotate: 90deg;
  margin-right: 16px;
}