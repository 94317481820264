@value  TEXT_WHITE, BUTTON_SUCCESS, BUTTON_DANGER, BUTTON_WARNING, BUTTON_BLUE_PRIMARY, BORDER_DARK_GRAY  from '../../constants/index.css';

.buttonWrapper {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  display: inline-block;
}

.buttonWrapperInline {
  display: inline-block;
}

.buttonContainer { 
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.default{
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  width: 200px;
  color: TEXT_WHITE;
  border: none;
  background-color: BUTTON_SUCCESS;
  border-radius: 50px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 8px;
}

.disabled{
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  width: 200px;
  color: TEXT_WHITE;
  border: none;
  background-color: BORDER_DARK_GRAY;
  border-radius: 50px;
  text-align: center;
  font-size: 15px;
  cursor: default;
  margin-bottom: 8px;
}

.default:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.disabled:hover {
  box-shadow: none;
}

.danger{
  composes: default;
  background-color: BUTTON_DANGER;
  border-width: 0;
}

.warning {
  composes: default;
  background-color: BUTTON_WARNING;
  border-width: 0;
}

.primaryBlue{
  composes: default;
  border-color: BUTTON_BLUE_PRIMARY;
  background-color: BUTTON_BLUE_PRIMARY;
  width: 200px;
}

.defaultGrey{
  composes: default;
  width: 200px;
  background-color: BORDER_DARK_GRAY;
  cursor: pointer;
}

.primaryBlueMenu{
  composes: primaryBlue;
 width: 200px;
}

.defaultMenu{
  composes: default;
  width: 200px;
}

.dangerMenu{
  composes: danger;
  width: 200px;
  margin-bottom: 8px;
}

.textLike {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}