@value BACKGROUND_GRAY from './constants/theme.css';

.loaderWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app {
  height: 100%;
  background: BACKGROUND_GRAY;
}
