.default {
  padding: 8px 32px;
  margin: 8px;
}

.dangerColor {
  background-color: #f44336;
  color: #fff;
}
.dangerColor:hover {
  background-color: #d32f2f;
}

.danger {
  composes: default;
  composes: dangerColor;
}

.dangerColor {
  composes: dangerColor;
}
