.sidebar {
  background-color: #262626; /* TODO constant */
  padding-top: 2px;
  height: 100%;
}

.listItem {
  padding: 8px;
  justify-content: center;
}

.dashboardIcon {
  color: #fff;
  transition: color 0.2s ease-in-out;
}

.dashboardIconActive {
  color: #3f94ef;
  transition: color 0.2s ease-in-out;
}

.settingsLink {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}
