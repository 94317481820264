.modal {
  overflow-y: auto;
}

.content {
  padding: 32px 52px 52px 52px;
}

.inputTitle {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
}

.menuItem {
  display: flex;
  justify-content: space-between;
}
