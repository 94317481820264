@value BLUE_500, BLUE_300, BLUE_200, BACKGROUND_GRAY, TITLE_BLACK from '../../constants/theme.css';


.section {
  font-family: 'Silka', Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 1.5em 2em .5em;
  /* display: inline-flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column; */
}

.sectionHeader {
  font-weight: 600;
  margin: 0em -2em 1em;
  padding: 1em 2em;
  background-color: BACKGROUND_GRAY;
  border-top: 1px solid BLUE_300;
  color: TITLE_BLACK;
}

.item {
  display: grid;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.highlighted {
  color: black;
  font-weight: bold;
}
