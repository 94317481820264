.small {
  position: relative;
  width: 32px;
  height: 32px;
}

.medium {
  position: relative;
  width: 48px;
  height: 48px;
}

.large {
  position: relative;
  width: 70px;
  height: 70px;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.verifiedBadge {
  position: absolute;
  background-color: white;
  border-radius: 100%;
}

.verifiedBadge--small {
  width: 12px;
  height: 12px;
  padding: 1px;
  bottom: 1px;
  right: 1px;
}

.verifiedBadge--medium {
  width: 18px;
  height: 18px;
  padding: 2px;
  right: 2px;
  bottom: 2px;
}

.verifiedBadge--large {
  width: 20px;
  height: 20px;
  padding: 2px;
  right: 4px;
  bottom: 4px;
}