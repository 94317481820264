.addPositionsButton {
  background-color: #2980B9;
  color: #fff;
}

.infoNote {
  color: #ACACAC;
  font-style: italic;
  font-size: 0.875rem;
}
