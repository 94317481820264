@value TEXT_WHITE, BACKGROUND_GRAY, BORDER_DARK_GRAY from '../constants/index.css';

.body {
  background-color: BACKGROUND_GRAY;
  height: calc(100% - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  height: 100%;
}

.cartWrapper {
  padding: 20px;
}

.contentWrapper {
  background-color: TEXT_WHITE;
  border-radius: 10px;
  min-height: 100px;
  width: 400px;
  padding: 15px;
  border: 1px solid BORDER_DARK_GRAY;
  text-align: center;
}

.loginForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}


.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
