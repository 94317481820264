.modal {
  overflow-y: auto;
}

.content {
  padding: 32px 52px 8px 52px;
}

.inputTitle {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
}

.error {
  font-size: 12px;
  color: #EF4E4E;
}
