.button {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  color: #363e48;
  background-color: #eee;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: bold;
}

.buttonOutline {
  padding: 3px;
  border-style: solid;
  border-width: 3px;
  border-color: rgba(238,238,238,0);

  border-radius: 10px; /* border-width + padding + border-radius of child */

  transition: all ease-in-out 0.2s;
}

.buttonOutline:hover {
  border-color: rgba(238,238,238,0.5);
}

.buttonOutlineProcessing {
  composes: buttonOutline;
  border-color: rgba(238,238,238,0.75);
}

.buttonOutlineSelected {
  composes: buttonOutline;
  border-color: rgba(238,238,238,1);
}
