@value BACKGROUND_GRAY from '../../constants/theme.css';

.wrapper {
  min-height: 110%;
  display: flex;
}

.groupSelector {
  position: relative;
  display: flex;
  width: 80px;
  background-color: #262626; 
}

.sidebar {
  position: relative;
  background-color: #353535;
  color: #fff;
}

.groupSidebar {
  composes: sidebar;
}

.body {
  background-color: BACKGROUND_GRAY;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  margin: 40px;
}
