.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  border: 1px solid #999;
  transform: translate(-50%, -50%) !important;
  height: auto;
  outline: none;
  padding: 46px;
  background-color: #fff;
  max-width: 470px;
  justify-content: center;
  text-align: start;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50.1%, -50.1%) !important;
  display: flex;
  max-width: calc(100% - 60px);
  min-width: 85vw;
  height: auto;
  max-height: calc(100% - 60px);
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 6px -3px, rgba(0, 0, 0, 0.14) 0px 10px 14px 1px, rgba(0, 0, 0, 0.12) 0px 4px 18px 3px;
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
}

.close {
  position: absolute;
  right: 2px;
  top: 2px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #171717;
}

.subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.positionDetails {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6B7280;
}

.buttonContainer {
  display: flex;
  flex: 1;
  margin-top: 32px;
  text-align: center;
}

.position {
  margin: 32px 0px 32px 0px;
}

.button {
  flex: 1;
  margin: 0px 8px 0px 8px;
}