@value TEXT_WHITE, BORDER_DARK_GRAY from '../constants/index.css';
@value BACKGROUND_GRAY from '../constants/theme.css';

.body {
  background-color: BACKGROUND_GRAY;
  display: flex;
  justify-content: center;
}

.setupBusiness {
  composes: body;
  min-height: 100%;
  height: auto;
}

.logo {
  height: 90px;
  width: 250px;
  margin: 50px 0;
}

.container {
  width: 100%;
  height: fit-content;
  background-color: BACKGROUND_GRAY;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loaderWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
