@value TEXT_WHITE, BUTTON_SUCCESS from '../../constants/index.css';

.container {
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  border-radius: 25px;
}

.previewHeading {
  height: 35px;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.inputGroup {
  margin: 20px 0;
}

.formInput {
  margin: 10px 0;
}

.modalButton button{
  margin: 20px;
}

.placeholderColor {
  color: rgba(0, 0, 0, 0.50);
}

.error {
  width: 100%;
  padding: 5px 0;
  color:red;
  border-top: 1px solid red;
  font-style: italic;
  max-height: 30px;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-top: 20px;
}

.thankyou {
  margin: 0 5px;
}

.upfrontHeading {
  text-align: center;
}

.asapButton {
  width: 35%;
  font-size: 10px;
  margin-right: 12px;
}

.formGroup {
  align-items: center;
}
